// @ts-nocheck
"use client";

import { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Cookies from "js-cookie";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { toast } from "react-toastify";
import { useAppDispatch } from "@/lib/hooks";
import { setUser } from "@/slices/userSlice";

import { getPublicToken } from "@/app/api/auth";
import { useAuth } from "@/context/AuthContext";

import "./styles.scss";
import { getUserInfo } from "../api/user";
import { Typography } from "@mui/material";

const SignInForm = () => {
  useEffect(() => {
    getPublicToken()
      .then((res) => res.data)
      .then((data) => {
        Cookies.set("token", data.data.token, { expires: 7 });
      });
  }, []);

  const router = useRouter();
  const dispatch = useAppDispatch();

  const { login } = useAuth();

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("*"),
    password: Yup.string().required("*"),
    remember: Yup.boolean(),
  });

  const initialValues = {
    email: "",
    password: "",
    remember: false,
  };

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      const res = await login(values.email, values.password);

      if (res.status === 200) {
        const userRes = await getUserInfo();
        dispatch(setUser(userRes.data.data.user));

        toast.success("Login successful");

        if (userRes.data.data.user.user_type === "admin_master") {
          router.push("/admin-tasks");
        } else {
          router.push("/dashboard");
        }
      } else {
        toast.error("Login failed: Invalid response status");
      }
    } catch (error) {
      toast.error(`Login failed: ${error.message || error}`);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <div className="sign-in-container">
          <div className="image-container">
            <div className="cover-image">
              <img
                className="cover-image-color"
                src="/white-gather.png"
                alt="Cover"
              />
            </div>
          </div>
          <div className="form-container">
            {/* <img className="logo-image" src="/gather-logo.svg" alt="Cover" /> */}
            <div>
              <h1 className="title">Log In</h1>
              <h1 className="description">Please enter your credentials</h1>
              <p className="label">
                E-Mail
                <ErrorMessage name="email" component="div" className="error" />
              </p>
              <Field
                type="email"
                id="email"
                name="email"
                placeholder="john.doe@company.com"
                className="input-field"
              />
            </div>
            <div>
              <p className="label">
                Password
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error"
                />
              </p>
              <Field
                type="password"
                id="password"
                name="password"
                placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                className="input-field"
              />
            </div>
            {/* <div className="remember">
              <p>
                <Field
                  type="checkbox"
                  id="remember"
                  name="remember"
                  className="checkbox"
                />{" "}
                Remember this device
              </p>
            </div> */}
            <div className="links-container">
              {/* <Link className="sub-heading" href="">
                Forgot Password?
              </Link> */}
              {/* <Link className="sub-heading" href="/signup">
                Create Account
              </Link> */}
            </div>
            <button className="submit-button" type="submit">
              <Typography className="submit-button-text">Log In</Typography>
            </button>
            {/* <div className="form-border"></div> */}
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default SignInForm;
